const SING_IN_LINK = '/login';
const SING_UP_LINK = '/';
const TASK_LINK = '/task';
const SEARCH_LINK = '/search';
const RESET_PASS_LINK = '/reset-password';
const FORGOT_PASS_LINK = '/forgot-pass';
const PROFILE_LINK = '/profile';

const ERROR_MESSAGES = {
  API: 'Ошибка запроса',
};

const timeRepeatCode = 60;

export {
  SING_IN_LINK,
  SING_UP_LINK,
  TASK_LINK,
  SEARCH_LINK,
  ERROR_MESSAGES,
  timeRepeatCode,
  RESET_PASS_LINK,
  FORGOT_PASS_LINK,
  PROFILE_LINK,
};
