import './ResetPassword.css';

import React, { useState } from 'react';

import Button from './Button';
import Fieldset from './Fieldset';
import Form from './Form';
import InputEmail from './InputEmail';
import InputPassword from './InputPassword';

const Profile = function Profile({
  userData,
  onLogout,
  error,
  onUpdateUserData,
}) {
  const [data, setData] = useState({
    email: userData.email,
    password: '',
  });
  // const [disabled, setDisabled] = useState(false);
  const [confCode, setConfCode] = useState('');

  const handlerDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handlerCodeChange = (e) => {
    setConfCode(e.target.value);
  };

  const isEmpty = (e) => !Object.keys(e).length;

  const handleUpdateUserDataSubmit = (e) => {
    e.preventDefault();

    const newUserData = {};

    if (data.email !== userData.email) {
      newUserData.email = data.email;
    }

    if (data.password !== '') {
      newUserData.password = data.password;
    }
    if (confCode !== '') {
      newUserData.email_confirmation_code = confCode;
    }

    console.log('form');

    if (!isEmpty(newUserData)) {
      onUpdateUserData({
        newUserData,
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleUpdateUserDataSubmit}>
        <Fieldset>
          <InputEmail
            title="Введите email:"
            name="email"
            value={data.email}
            onChange={handlerDataChange}
            error={error}
            description="На эту почту вы получите код подтверждения"
            // disabled={!isEmpty(userData.email) && (true)}
          />

          {!isEmpty(data.email)
          && (
            <Button name="Подтвердить" />
          )}

          <input
            type="text"
            className="input-box__input"
            title="Введите код подтверждения:"
            onChange={handlerCodeChange}
            value={confCode}
            // error={error}
          />

          <InputPassword
            title="Введите новый пароль:"
            name="password"
            value={data.password}
            onChange={handlerDataChange}
            error={error}
            description=""
          />
        </Fieldset>
        <Button name="Сохранить" />
      </Form>
      <button
        type="submit"
        onClick={onLogout}
        style={{ cursor: 'pointer', margin: '30px' }}
      >
        Выход
      </button>
    </>
  );
};

export default Profile;
